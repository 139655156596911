<template>
  <div class="main-container-page">
    <div class="header">
      <header-bar v-once></header-bar>
    </div>
    <div class="main-page">
      <router-view v-if="isRouterAlive"></router-view>
    </div>
  </div>
</template>

<script>
import headerBar from "@/components/headerBar";
export default {
  name: "",
  components: {
    headerBar,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.main-container-page {
  width: 100%;
  height: 100%;
  //min-width: 1920px;
  min-height: 930px;
  @height: 71px;
  .header {
    width: 100%;
    height: @height;
  }
  .main-page {
    width: 100%;
    height: calc(100% - @height);
  }
}
</style>
